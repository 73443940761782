import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SiteImage from "../components/site_image"

const About = () => {
  return (
    <Layout>
      <Seo title="About" />
      <div className="about-image">
        <SiteImage />
      </div>
      <p>
        I'm Heather. I am a home cook, but a relatively experienced one. I cook for my family,
        including two (currently) elementary-aged children who would prefer not to eat any of
        "that fancy stuff". We do this because I love to cook, and because my husband would like
        an excuse to eat more of "that fancy stuff" in all of the cookbooks I own.
      </p>
      <p>
        I make recipes from cookbooks, and show you (mostly on the first try!), how it 
        goes for me. I'll give you tips and show you tricks that I have learned through 
        my years of home cooking. In the end, I'll let you know how easy (or hard), 
        the recipes are to recreate at home, and whether you need any special equipment
        to successfully pull it off.
      </p>
      <p>
        Occasionally, I'll include a video of a more original recipe. Sometimes it will be a 
        family recipe I may have tweaked. Sometimes, a recipe that I have modified from other 
        sources so much so that I no longer have any idea where it came from. And sometimes,
        it will be something that came directly from my own brain, often inspired by the 
        contents of my refrigerator and cabinets.
      </p>
      <p>
        I hope you enjoy watching me cook! Let me know if you try any of the recipes I've shown
        you, and how they work out for you. All of my social media is linked below, and in every
        YouTube video I post.
      </p>
    </Layout>
  )
}

export default About;